<template>
  <div id="mian">
    <div class="header">
      <span @click="toTypeUrl('shoplist_xq')">店铺详情</span>
      <span id="fzcolor">报备商户号信息</span>
    </div>
    <div v-auth="'ACCOUNT:STORE:STORES:TRADE'">
      <div class="desc">
        <h5 class="jsxx">报备商户号信息</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="padding-left: 10px">
              <p>创建时间</p>
            </td>
            <td>
              <p>报备商户</p>
              <p>报备商户号</p>
            </td>
            <td>
              <p>关联渠道</p>
            </td>
            <td>
              <p>店铺内部ID</p>
            </td>
            <td>
              <p>激活状态</p>
            </td>
            <td>
              <p>审核状态</p>
            </td>
            <td class="tableLineHead">
              <th>
                <p>支付产品</p>
                <p>第三方商户号</p>
                <p>开通状态</p>
              </th>
            </td>
            <td>
              <p>备注</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v,index) in tabData" :key="index">
              <td style="padding-left: 10px">
                  <p>{{ v.createTime }}</p>
              </td>
              <td>
                  <p>{{ v.tradeMchName }}</p>
                  <p>{{ v.tradeMchNo }}</p>
              </td>
              <td>
                  <p>{{ v.mchNo }}</p>
              </td>
              <td>
                  <p>{{ v.actualInternalId ? v.actualInternalId : "" }}</p>
              </td>
              <td>
                  <p v-show="OFFON_ActivateStatus != 'ACCOUNT:STORE:STORES:TRADE/ENABLED'"> {{ v.activateStatus==true ? "已开启" : "已关闭" }}</p>
                  <p v-auth="'ACCOUNT:STORE:STORES:TRADE/ENABLED'">
                    <el-switch
                      v-model="v.activateStatus"
                      @change="setStatus(v)"
                      active-color="#13ce66"
                    >
                    </el-switch>
                  </p>
              </td>
              <td>
                  <p>{{ v.auditStatus ? v.auditStatus : "" }}</p>
              </td>
              <td class="tableLineTit">
                <th v-for="(val,ind) in v.tradeProducts" :key="ind">
                  <p>{{ productCode[val.productCode] }} </p>
                  <p>{{ val.productChannelMchNo ? val.productChannelMchNo : "" }}</p>
                  <p>{{ val.productStatus ? val.productStatus : "" }}</p>
                </th>      
              </td>
              <td>
                  <p>{{ v.remark ? v.remark : "" }}</p>
              </td>
              <td>
                <p>
                  <i v-auth="'ACCOUNT:STORE:STORES:TRADE/DETAIL'" @click="tabShow(v)" class="lianjie">详情</i>
                  <i v-auth="'ACCOUNT:STORE:STORES:TRADE/SYNC'" class="lianjie" @click="setTradeSync(v.tradeMchNo)">同步</i>
                  <i v-auth="'ACCOUNT:STORE:STORES:TRADE/EDIT'" @click="editTrade(v)" class="lianjie">修改</i>
                  <i @click="del(v.tradeMchNo)" class="lianjie" v-auth="'ACCOUNT:STORE:STORES:TRADE/DELETE'">删除</i>
                </p>
                <p>
                  <i v-auth="'ACCOUNT:STORE:STORES:TRADE/WX'" class="lianjie" @click="toTypeUrl('shoplist_xq_shop_ty', {tradeMchNo: v.tradeMchNo,})">特约配置</i>
                  <i v-auth="'ACCOUNT:STORE:STORES:TRADE/PRODUCT'"  @click="toProductReport(v)" class="lianjie">产品报备</i>
                  <i v-auth="'ACCOUNT:STORE:STORES:TRADE/REMARK'"  @click="setRemark(v)"  class="lianjie" >备注</i >
                </p>
              </td>
          </tr>
        </table>
      </div>
      <el-pagination  
        ref="pagination"     
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNum"
      >
      </el-pagination>
      <el-dialog
        title="报备商户号信息详情"
        :visible.sync="detailShow"
        width="980px"
      >
        <div class="clear">
          <ul class="overAccount_ul"
            v-if="
              InfoType != 'MANUAL' &&
              (detail.merchantType == 'ENTERPRISE' ||
                detail.merchantType == 'INDIVIDUAL_BUSINESS')
            "
          >
            <li class="ul_left">
              <div class="overAccount_ul_title">店铺名称</div>
              <div class="h36">{{ detail.storeName }}</div>
              <div class="overAccount_ul_title">店铺编号</div>
              <div class="h36">{{ detail.storeId }}</div>
              <div class="overAccount_ul_title">店铺内部ID</div>
              <div class="h36">{{ detail.internalId }}</div>
              <div class="overAccount_ul_title">经营类目</div>
              <div class="h36">{{ detail.category }}</div>
              <div class="overAccount_ul_title">地区</div>
              <div class="h36">
                {{ detail.province }}-{{ detail.city }}-{{ detail.county }}
              </div>
              <div class="overAccount_ul_title">店铺证件类型</div>
              <div class="h36">{{ licenceType[detail.licenceType] }}</div>
              <div class="overAccount_ul_title">注册地址</div>
              <div class="h36">{{ detail.companyAddress }}</div>
              <div class="overAccount_ul_title">营业执照</div>
              <div class="h36">
                <a
                  href="javascript:;"
                  class="lianjie"
                  style="padding-left: 0"
                  @click="showImg(detail.licencePicture)"
                  >{{ detail.licencePicture ? "点击预览" : "" }}</a
                >
              </div>
              <div class="overAccount_ul_title">法人证件类型</div>
              <div class="h36">{{ legalPersonCemType[detail.legalPersonCemType] }}</div>
              <div class="overAccount_ul_title">法人证件号码</div>
              <div class="h36">{{ detail.legalPersonCemNo }}</div>
              <div class="overAccount_ul_title">法人证件反面照</div>
              <div class="h36">
                <a
                  href="javascript:;"
                  class="lianjie"
                  style="padding-left: 0"
                  @click="showImg(detail.idcardReversePicture)"
                  >{{ detail.idcardReversePicture ? "点击预览" : "" }}</a
                >
              </div>
              <div class="overAccount_ul_title">法人为受益人</div>
              <div class="h36">
                <span v-show="detail.legalPersonIsBene==1">是</span>
                <span v-show="detail.legalPersonIsBene==2">否</span>
              </div>
            </li>
            <li class="ul-right">
              <div class="overAccount_ul_title">所属商户</div>
              <div class="h36">{{ detail.mchName }}</div>
              <div class="overAccount_ul_title">店铺简称</div>
              <div class="h36">{{ detail.storeShortName }}</div>
              <div class="overAccount_ul_title">客服电话</div>
              <div class="h36">{{ detail.servicePhone }}</div>
              <div class="overAccount_ul_title">店铺经营地址</div>
              <div class="h36">{{ detail.storeAddress }}</div>
              <div class="overAccount_ul_title">店铺主体类型</div>
              <div class="h36">{{ merchantType[detail.merchantType] }}</div>
              <div class="overAccount_ul_title">证件编号</div>
              <div class="h36">{{ detail.licenceNo }}</div>
              <div class="overAccount_ul_title">营业执照有效期</div>
              <div
                class="h36"
                v-if="!detail.licenceStartDate && !detail.licenceEndDate"
              ></div>
              <div class="h36" v-else>
                {{ detail.licenceStartDate
                }}<span v-if="detail.licenceStartDate && detail.licenceEndDate"
                  >至</span
                >{{
                  detail.licenceEndDate == "forever"
                    ? "长期"
                    : detail.licenceEndDate
                }}
              </div>
              <div class="overAccount_ul_title">法人姓名</div>
              <div class="h36">{{ detail.legalPersonName }}</div>
              <div class="overAccount_ul_title">法人证件有效期</div>
              <div
                class="h36"
                v-if="
                  !detail.legalPersonCemStartDate &&
                  !detail.legalPersonCemEndDate
                "
              ></div>
              <div class="h36" v-else>
                {{ detail.legalPersonCemStartDate
                }}<span
                  v-if="
                    detail.legalPersonCemStartDate &&
                    detail.legalPersonCemEndDate
                  "
                  >至</span
                >{{
                  detail.legalPersonCemEndDate == "forever"
                    ? "长期"
                    : detail.legalPersonCemEndDate
                }}
              </div>
              <div class="overAccount_ul_title">法人证件正面照片</div>
              <div class="h36">
                <a
                  href="javascript:;"
                  class="lianjie"
                  style="padding-left: 0"
                  @click="showImg(detail.idcardNormalPicture)"
                  >{{ detail.idcardNormalPicture ? "点击预览" : "" }}</a
                >
              </div>
              <div class="overAccount_ul_title">法人证件照地址</div>
              <div class="h36">{{ detail.idAddress }}</div>
              <div class="overAccount_ul_title">法人为联系人</div>
              <div class="h36">
                <span v-show="detail.legalPersonIsContact==1">是</span>
                <span v-show="detail.legalPersonIsContact==2">否</span>
              </div>
            </li>
          </ul>
          <ul class="overAccount_ul"
            v-if="
              InfoType != 'MANUAL' &&
              (detail.merchantType == 'ENTERPRISE' ||
                detail.merchantType == 'INDIVIDUAL_BUSINESS')
            "
          >
            <li class="ul_left">
              <div class="overAccount_ul_title">联系人</div>
              <div class="h36">{{ detail.contactName }}</div>
              <div class="overAccount_ul_title">联系人邮箱</div>
              <div class="h36">{{ detail.contactEmail }}</div>
              <div class="overAccount_ul_title">联系人证件号码</div>              
              <div class="h36">{{ detail.contactIdCard }}</div>
              <div class="overAccount_ul_title">联系人证件正面照片</div>
              <div class="h36">
                <a
                  href="javascript:;"
                  class="lianjie"
                  style="padding-left: 0"
                  @click="showImg(detail.contactIdNomalPic)"
                  >{{ detail.contactIdNomalPic ? "点击预览" : "" }}</a
                >
              </div>
              <div class="overAccount_ul_title">业务办理授权函</div>
              <div class="h36">
                <a
                  href="javascript:;"
                  class="lianjie"
                  style="padding-left: 0"
                  @click="showImg(detail.authorizationLetter)"
                  >{{ detail.authorizationLetter ? "点击预览" : "" }}</a
                >
              </div>
              <div class="overAccount_ul_title">结算银行卡开户人</div>
              <div class="h36">{{ detail.accountName }}</div>
              <div class="overAccount_ul_title">结算卡联行号</div>
              <div class="h36">{{ detail.interBranchesNo }}</div>
              <div class="overAccount_ul_title">联系人微信号</div>
              <div class="h36">
                {{ detail.contactWechat ? detail.contactWechat : "" }}
              </div>
              <div class="overAccount_ul_title">创建时间</div>
              <div class="h36">{{ detail.createTime }}</div>
              <div class="overAccount_ul_title">最后修改时间</div>
              <div class="h36">{{ detail.lastUpdateTime }}</div>
              <div class="overAccount_ul_title">审核状态</div>
              <div class="h36">{{ detail.auditStatus }}</div>
              <div class="overAccount_ul_title">审核状态错误描述</div>
              <div class="h36">{{ detail.auditErrMsg }}</div>
            </li>
            <li class="ul-right">
              <div class="overAccount_ul_title">联系人手机</div>
              <div class="h36">{{ detail.contactPhone }}</div>
              <div class="overAccount_ul_title">联系人证件类型</div>
              <div class="h36">
                {{ legalPersonCemType[detail.contactCemType] }}
              </div>
              <div class="overAccount_ul_title">联系人证件有效期</div>
              <div
                class="h36"
                v-if="
                  !detail.contactValidStartDate &&
                  !detail.contactValidSEndDate
                "
              ></div>
              <div class="h36" v-else>
                {{ detail.contactValidStartDate
                }}<span
                  v-if="
                    detail.contactValidStartDate &&
                    detail.contactValidSEndDate
                  "
                  >至</span
                >{{
                  detail.contactValidSEndDate == "forever"
                    ? "长期"
                    : detail.contactValidSEndDate
                }}
              </div>
              <div class="overAccount_ul_title">联系人证件反面照片</div>
              <div class="h36">
                <a
                  href="javascript:;"
                  class="lianjie"
                  style="padding-left: 0"
                  @click="showImg(detail.contactIdReversePic)"
                  >{{ detail.contactIdReversePic ? "点击预览" : "" }}</a
                >
              </div>
              <div class="overAccount_ul_title">结算银行卡号</div>
              <div class="h36">{{ detail.accountNo }}</div>
              <div class="overAccount_ul_title">结算卡类型</div>
              <div class="h36">{{ accountType[detail.accountType] }}</div>
              <div class="overAccount_ul_title">店铺支付宝账户</div>
              <div class="h36">
                {{ detail.alipayId ? detail.alipayId : "" }}
              </div>
              <div class="overAccount_ul_title">使用场景</div>
              <div class="h36">{{ config.useScene == "OFFLINE" ? "线下" : "线上" }}</div>
              <div class="overAccount_ul_title">审核时间</div>
              <div class="h36">
                {{ detail.checkTime ? detail.checkTime : "" }}
              </div>
              <div class="overAccount_ul_title">最后修改人</div>
              <div class="h36">{{ detail.lastUpdateUser }}</div>
              <div class="overAccount_ul_title">审核状态错误码</div>
              <div class="h36">{{ detail.auditErrCode }}</div>
            </li>
          </ul>
          <ul class="overAccount_ul"
            v-if="
              InfoType != 'MANUAL' &&
              detail.merchantType != 'ENTERPRISE' &&
              detail.merchantType != 'INDIVIDUAL_BUSINESS' &&
              detail.merchantType != 'MICRO_ENTERPRISE'
            "
          >
            <li class="ul_left">
              <div class="overAccount_ul_title">店铺名称</div>
              <div class="h36">{{ detail.storeName }}</div>
              <div class="overAccount_ul_title">店铺编号</div>
              <div class="h36">{{ detail.storeId }}</div>
              <div class="overAccount_ul_title">店铺内部ID</div>
              <div class="h36">{{ detail.internalId }}</div>
              <div class="overAccount_ul_title">经营类目</div>
              <div class="h36">{{ detail.category }}</div>
              <div class="overAccount_ul_title">地区</div>
              <div class="h36">
                {{ detail.province }}-{{ detail.city }}-{{ detail.county }}
              </div>
              <div class="overAccount_ul_title">店铺证件类型</div>
              <div class="h36">{{ licenceType[detail.licenceType] }}</div>
              <div class="overAccount_ul_title">注册地址</div>
              <div class="h36">{{ detail.companyAddress }}</div>
              <div class="overAccount_ul_title">证件照片</div>
              <div class="h36">
                <a
                  href="javascript:;"
                  class="lianjie"
                  style="padding-left: 0"
                  @click="showImg(detail.licencePicture)"
                  >{{ detail.licencePicture ? "点击预览" : "" }}</a
                >
              </div>
              <div class="overAccount_ul_title">法人姓名</div>
              <div class="h36">{{ detail.legalPersonName }}</div>
              <div class="overAccount_ul_title">法人证件有效期</div>
              <div
                class="h36"
                v-if="
                  !detail.legalPersonCemStartDate &&
                  !detail.legalPersonCemEndDate
                "
              ></div>
              <div class="h36" v-else>
                {{ detail.legalPersonCemStartDate
                }}<span
                  v-if="
                    detail.legalPersonCemStartDate &&
                    detail.legalPersonCemEndDate
                  "
                  >至</span
                >{{
                  detail.legalPersonCemEndDate == "forever"
                    ? "长期"
                    : detail.legalPersonCemEndDate
                }}
              </div>
              <div class="overAccount_ul_title">法人证件正面照</div>
              <div class="h36">
                <a
                  href="javascript:;"
                  class="lianjie"
                  style="padding-left: 0"
                  @click="showImg(detail.idcardNormalPicture)"
                  >{{ detail.idcardNormalPicture ? "点击预览" : "" }}</a
                >
              </div>
              <div class="overAccount_ul_title">法人证件照地址</div>
               <div class="h36">{{ detail.idAddress }}</div>
            </li>
            <li class="ul-right">
              <div class="overAccount_ul_title">所属商户</div>
              <div class="h36">{{ detail.mchName }}</div>
              <div class="overAccount_ul_title">店铺简称</div>
              <div class="h36">{{ detail.storeShortName }}</div>
              <div class="overAccount_ul_title">客服电话</div>
              <div class="h36">{{ detail.servicePhone }}</div>
              <div class="overAccount_ul_title">店铺经营地址</div>
              <div class="h36">{{ detail.storeAddress }}</div>
              <div class="overAccount_ul_title">店铺主体类型</div>
              <div class="h36">{{ merchantType[detail.merchantType] }}</div>
              <div class="overAccount_ul_title">证件编号</div>
              <div class="h36">{{ detail.licenceNo }}</div>
              <div class="overAccount_ul_title">登记证件有效期</div>
              <div
                class="h36"
                v-if="!detail.licenceStartDate && !detail.licenceEndDate"
              ></div>
              <div class="h36" v-else>
                {{ detail.licenceStartDate
                }}<span v-if="detail.licenceStartDate && detail.licenceEndDate"
                  >至</span
                >{{
                  detail.licenceEndDate == "forever"
                    ? "长期"
                    : detail.licenceEndDate
                }}
              </div>
              <div class="overAccount_ul_title">单位证明函</div>
              <div class="h36">
                <a
                  href="javascript:;"
                  class="lianjie"
                  style="padding-left: 0"
                  @click="showImg(detail.licenceCompanyPicture)"
                  >{{ detail.licenceCompanyPicture ? "点击预览" : "" }}</a
                >
              </div>
              <div class="overAccount_ul_title">法人证件类型</div>
              <div class="h36">{{ legalPersonCemType[detail.legalPersonCemType] }}</div>
              <div class="overAccount_ul_title">法人证件号码</div>
              <div class="h36">{{ detail.legalPersonCemNo }}</div>
              <div class="overAccount_ul_title">法人证件反面照片</div>
              <div class="h36">
                <a
                  href="javascript:;"
                  class="lianjie"
                  style="padding-left: 0"
                  @click="showImg(detail.idcardReversePicture)"
                  >{{ detail.idcardReversePicture ? "点击预览" : "" }}</a
                >
              </div>
               <div class="overAccount_ul_title">法人为受益人</div>
              <div class="h36">
                <span v-show="detail.legalPersonIsBene==1">是</span>
                <span v-show="detail.legalPersonIsBene==2">否</span>
              </div>
            </li>
          </ul>
          <ul class="overAccount_ul"
            v-if="
              InfoType != 'MANUAL' &&
              detail.merchantType != 'ENTERPRISE' &&
              detail.merchantType != 'INDIVIDUAL_BUSINESS' &&
              detail.merchantType != 'MICRO_ENTERPRISE'
            "
          >
            <li class="ul_left">
              <div class="overAccount_ul_title">法人为联系人</div>
              <div class="h36">
                <span v-show="detail.legalPersonIsContact==1">是</span>
                <span v-show="detail.legalPersonIsContact==2">否</span>
              </div>
              <div class="overAccount_ul_title">联系人手机</div>
              <div class="h36">{{ detail.contactPhone }}</div>
              <div class="overAccount_ul_title">联系人证件类型</div>
              <div class="h36">
                {{ legalPersonCemType[detail.contactCemType] }}
              </div>
              <div class="overAccount_ul_title">联系人证件有效期</div>
              <div
                class="h36"
                v-if="
                  !detail.contactValidStartDate &&
                  !detail.contactValidSEndDate
                "
              ></div>
              <div class="h36" v-else>
                {{ detail.contactValidStartDate
                }}<span
                  v-if="
                    detail.contactValidStartDate &&
                    detail.contactValidSEndDate
                  "
                  >至</span
                >{{
                  detail.contactValidSEndDate == "forever"
                    ? "长期"
                    : detail.contactValidSEndDate
                }}
              </div>
              <div class="overAccount_ul_title">联系人证件反面照片</div>
              <div class="h36">
                <a
                  href="javascript:;"
                  class="lianjie"
                  style="padding-left: 0"
                  @click="showImg(detail.contactIdReversePic)"
                  >{{ detail.contactIdReversePic ? "点击预览" : "" }}</a
                >
              </div>
              <div class="overAccount_ul_title">结算银行卡号</div>
              <div class="h36">{{ detail.accountNo }}</div>
              <div class="overAccount_ul_title">结算卡类型</div>
              <div class="h36">{{ accountType[detail.accountType] }}</div>
              <div class="overAccount_ul_title">店铺支付宝账号</div>
              <div class="h36">
                {{ detail.alipayId ? detail.alipayId : "" }}
              </div>
              <div class="overAccount_ul_title">使用场景</div>
              <div class="h36">{{ config.useScene == "OFFLINE" ? "线下" : "线上" }}</div>
              <div class="overAccount_ul_title">审核时间</div>
              <div class="h36">
                {{ detail.checkTime ? detail.checkTime : "" }}
              </div>
              <div class="overAccount_ul_title">最后修改人</div>
              <div class="h36">{{ detail.lastUpdateUser }}</div>
              <div class="overAccount_ul_title">审核状态</div>
              <div class="h36">{{ detail.auditStatus }}</div>
            </li>
            <li class="ul-right">
              <div class="overAccount_ul_title">联系人</div>
              <div class="h36">{{ detail.contactName }}</div>
              <div class="overAccount_ul_title">联系人邮箱</div>
              <div class="h36">{{ detail.contactEmail }}</div>
              <div class="overAccount_ul_title">联系人证件号码</div>
              <div class="h36">{{ detail.contactIdCard }}</div>
              <div class="overAccount_ul_title">联系人证件正面照片</div>
              <div class="h36">
                <a
                  href="javascript:;"
                  class="lianjie"
                  style="padding-left: 0"
                  @click="showImg(detail.contactIdNomalPic)"
                  >{{ detail.contactIdNomalPic ? "点击预览" : "" }}</a
                >
              </div>
              <div class="overAccount_ul_title">业务办理授权函</div>
              <div class="h36">
                <a
                  href="javascript:;"
                  class="lianjie"
                  style="padding-left: 0"
                  @click="showImg(detail.authorizationLetter)"
                  >{{ detail.authorizationLetter ? "点击预览" : "" }}</a
                >
              </div>
              <div class="overAccount_ul_title">结算银行卡开户人</div>
              <div class="h36">{{ detail.accountName }}</div>
              <div class="overAccount_ul_title">结算卡联行号</div>
              <div class="h36">{{ detail.interBranchesNo }}</div>
              <div class="overAccount_ul_title">联系人微信号</div>
              <div class="h36">
                {{ detail.contactWechat ? detail.contactWechat : "" }}
              </div>
              <div class="overAccount_ul_title">创建时间</div>
              <div class="h36">{{ detail.createTime }}</div>
              <div class="overAccount_ul_title">最后修改时间</div>
              <div class="h36">{{ detail.lastUpdateTime }}</div>
              <div class="overAccount_ul_title">审核状态错误码</div>
              <div class="h36">{{ detail.auditErrCode }}</div>
              <div class="overAccount_ul_title">审核状态错误描述</div>
              <div class="h36">{{ detail.auditErrMsg }}</div>
            </li>
          </ul>
          <ul class="overAccount_ul"
            v-if="
              InfoType != 'MANUAL' && detail.merchantType == 'MICRO_ENTERPRISE'
            "
          >
            <li class="ul_left">
              <div class="overAccount_ul_title">店铺名称</div>
              <div class="h36">{{ detail.storeName }}</div>
              <div class="overAccount_ul_title">店铺编号</div>
              <div class="h36">{{ detail.storeId }}</div>
              <div class="overAccount_ul_title">店铺内部ID</div>
              <div class="h36">{{ detail.internalId }}</div>
              <div class="overAccount_ul_title">经营类目</div>
              <div class="h36">{{ detail.category }}</div>
              <div class="overAccount_ul_title">地区</div>
              <div class="h36">
                {{ detail.province }}-{{ detail.city }}-{{ detail.county }}
              </div>
              <div class="overAccount_ul_title">小微经营类型</div>
              <div class="h36">{{ microType[detail.microType] }}</div>
              <div class="overAccount_ul_title">店铺地址</div>
              <div class="h36">{{ detail.shopAddress }}</div>
              <div class="overAccount_ul_title">店内环境照片</div>
              <div class="h36">
                <a
                  href="javascript:;"
                  class="lianjie"
                  style="padding-left: 0"
                  @click="showImg(detail.shopIndoorPicture)"
                  >{{ detail.shopIndoorPicture ? "点击预览" : "" }}</a
                >
              </div>
              <div class="overAccount_ul_title">法人证件类型</div>
              <div class="h36">{{ legalPersonCemType[detail.legalPersonCemType] }}</div>
              <div class="overAccount_ul_title">法人证件号码</div>
              <div class="h36">{{ detail.legalPersonCemNo }}</div>
              <div class="overAccount_ul_title">法人证件反面照片</div>
              <div class="h36">
                <a
                  href="javascript:;"
                  class="lianjie"
                  style="padding-left: 0"
                  @click="showImg(detail.idcardReversePicture)"
                  >{{ detail.idcardReversePicture ? "点击预览" : "" }}</a
                >
              </div>
              <div class="overAccount_ul_title">法人为受益人</div>
              <div class="h36">
                <span v-show="detail.legalPersonIsBene==1">是</span>
                <span v-show="detail.legalPersonIsBene==2">否</span>
              </div>
            </li>
            <li class="ul-right">
              <div class="overAccount_ul_title">所属商户</div>
              <div class="h36">{{ detail.mchName }}</div>
              <div class="overAccount_ul_title">店铺简称</div>
              <div class="h36">{{ detail.storeShortName }}</div>
              <div class="overAccount_ul_title">客服电话</div>
              <div class="h36">{{ detail.servicePhone }}</div>
              <div class="overAccount_ul_title">店铺经营地址</div>
              <div class="h36">{{ detail.storeAddress }}</div>
              <div class="overAccount_ul_title">店铺主体类型</div>
              <div class="h36">{{ merchantType[detail.merchantType] }}</div>
              <div class="overAccount_ul_title">店铺名称</div>
              <div class="h36">{{ detail.shopName }}</div>
              <div class="overAccount_ul_title">店铺门头照片</div>
              <div class="h36">
                <a
                  href="javascript:;"
                  class="lianjie"
                  style="padding-left: 0"
                  @click="showImg(detail.shopEntrancePicture)"
                  >{{ detail.shopEntrancePicture ? "点击预览" : "" }}</a
                >
              </div>
              <div class="overAccount_ul_title">法人姓名</div>
              <div class="h36">{{ detail.legalPersonName }}</div>
              <div class="overAccount_ul_title">法人证件有效期</div>
              <div
                class="h36"
                v-if="
                  !detail.legalPersonCemStartDate &&
                  !detail.legalPersonCemEndDate
                "
              ></div>
              <div class="h36" v-else>
                {{ detail.legalPersonCemStartDate
                }}<span
                  v-if="
                    detail.legalPersonCemStartDate &&
                    detail.legalPersonCemEndDate
                  "
                  >至</span
                >{{
                  detail.legalPersonCemEndDate == "forever"
                    ? "长期"
                    : detail.legalPersonCemEndDate
                }}
              </div>
              <div class="overAccount_ul_title">法人证件正面照片</div>
              <div class="h36">
                <a
                  href="javascript:;"
                  class="lianjie"
                  style="padding-left: 0"
                  @click="showImg(detail.idcardNormalPicture)"
                  >{{ detail.idcardNormalPicture ? "点击预览" : "" }}</a
                >
              </div>
              <div class="overAccount_ul_title">法人证件照地址</div>
              <div class="h36">{{ detail.idAddress }}</div>
              <div class="overAccount_ul_title">法人为联系人</div>
              <div class="h36">
                <span v-show="detail.legalPersonIsContact==1">是</span>
                <span v-show="detail.legalPersonIsContact==2">否</span>
              </div>
            </li>
          </ul>
          <ul class="overAccount_ul"
            v-if="
              InfoType != 'MANUAL' && detail.merchantType == 'MICRO_ENTERPRISE'
            "
          >
            <li class="ul_left">
              <div class="overAccount_ul_title">联系人</div>
              <div class="h36">{{ detail.contactName }}</div>
              <div class="overAccount_ul_title">联系人邮箱</div>
              <div class="h36">{{ detail.contactEmail }}</div>
              <div class="overAccount_ul_title">联系人证件号码</div>
              <div class="h36">{{ detail.contactIdCard }}</div>
              <div class="overAccount_ul_title">联系人证件正面照片</div>
              <div class="h36">
                <a
                  href="javascript:;"
                  class="lianjie"
                  style="padding-left: 0"
                  @click="showImg(detail.contactIdNomalPic)"
                  >{{ detail.contactIdNomalPic ? "点击预览" : "" }}</a
                >
              </div>
              <div class="overAccount_ul_title">业务办理授权函</div>
              <div class="h36">
                <a
                  href="javascript:;"
                  class="lianjie"
                  style="padding-left: 0"
                  @click="showImg(detail.authorizationLetter)"
                  >{{ detail.authorizationLetter ? "点击预览" : "" }}</a
                >
              </div>
              <div class="overAccount_ul_title">结算银行卡开户人</div>
              <div class="h36">{{ detail.accountName }}</div>
              <div class="overAccount_ul_title">结算卡联行号</div>
              <div class="h36">{{ detail.interBranchesNo }}</div>
              <div class="overAccount_ul_title">联系人微信号</div>
              <div class="h36">
                {{ detail.contactWechat ? detail.contactWechat : "" }}
              </div>
              <div class="overAccount_ul_title">创建时间</div>
              <div class="h36">{{ detail.createTime }}</div>
              <div class="overAccount_ul_title">最后修改时间</div>
              <div class="h36">{{ detail.lastUpdateTime }}</div>
              <div class="overAccount_ul_title">审核状态</div>
              <div class="h36">{{ detail.auditStatus }}</div>
              <div class="overAccount_ul_title">审核状态错误描述</div>
              <div class="h36">{{ detail.auditErrMsg }}</div>
            </li>
            <li class="ul-right">
              <div class="overAccount_ul_title">联系人手机</div>
              <div class="h36">{{ detail.contactPhone }}</div>
              <div class="overAccount_ul_title">联系人证件类型</div>
              <div class="h36">
                {{ legalPersonCemType[detail.contactCemType] }}
              </div>
              <div class="overAccount_ul_title">联系人证件有效期</div>
              <div
                class="h36"
                v-if="
                  !detail.contactValidStartDate &&
                  !detail.contactValidSEndDate
                "
              ></div>
              <div class="h36" v-else>
                {{ detail.contactValidStartDate
                }}<span
                  v-if="
                    detail.contactValidStartDate &&
                    detail.contactValidSEndDate
                  "
                  >至</span
                >{{
                  detail.contactValidSEndDate == "forever"
                    ? "长期"
                    : detail.contactValidSEndDate
                }}
              </div>
              <div class="overAccount_ul_title">联系人证件反面照片</div>
              <div class="h36">
                <a
                  href="javascript:;"
                  class="lianjie"
                  style="padding-left: 0"
                  @click="showImg(detail.contactIdReversePic)"
                  >{{ detail.contactIdReversePic ? "点击预览" : "" }}</a
                >
              </div>
              <div class="overAccount_ul_title">结算银行卡号</div>
              <div class="h36">{{ detail.accountNo }}</div>
              <div class="overAccount_ul_title">结算卡类型</div>
              <div class="h36">{{ accountType[detail.accountType] }}</div>
              <div class="overAccount_ul_title">店铺支付宝账户</div>
              <div class="h36">
                {{ detail.alipayId ? detail.alipayId : "" }}
              </div>
              <div class="overAccount_ul_title">使用场景</div>
              <div class="h36">{{ config.useScene == "OFFLINE" ? "线下" : "线上" }}</div>
              <div class="overAccount_ul_title">审核时间</div>
              <div class="h36">
                {{ detail.checkTime ? detail.checkTime : "" }}
              </div>
              <div class="overAccount_ul_title">最后修改人</div>
              <div class="h36">{{ detail.lastUpdateUser }}</div>
              <div class="overAccount_ul_title">审核状态错误码</div>
              <div class="h36">{{ detail.auditErrCode }}</div>
            </li>
          </ul>
          <ul class="overAccount_ul" v-if="InfoType == 'MANUAL'">
            <li class="ul_left">
              <div class="overAccount_ul_title">店铺编号</div>
              <div class="h36">{{ detail.storeId }}</div>
              <div class="overAccount_ul_title">商户编号</div>
              <div class="h36">{{ detail.mchId }}</div>
              <div class="overAccount_ul_title">商户平台显示</div>
              <div class="h36">{{ detail.showInMch ? "是" : "否" }}</div>
            </li>
            <li class="ul-right">
              <div class="overAccount_ul_title">店铺名称</div>
              <div class="h36">{{ detail.storeName }}</div>
              <div class="overAccount_ul_title">所属商户</div>
              <div class="h36">{{ detail.mchName }}</div>
            </li>
          </ul>
        </div>
        <h6>受益人信息</h6>
        <div class="tab1" style="padding: 0 20px; min-height: 100px">
          <table class="tab">
            <tr class="tab_title">
              <td style="width: 100px">
                <p>受益人名称</p>
              </td>
              <td style="width: 100px">
                <p>受益人证件类型</p>
              </td>
              <td style="width: 100px">
                <p>受益人证件号</p>
              </td>
              <td style="width: 100px">
                <p>证件有效期</p>
              </td>
              <td style="width: 100px">
                <p>受益人证件正面照片</p>
              </td>
              <td style="width: 100px">
                <p>受益人证件反面照片</p>
              </td>
              <td style="width: 100px">
                <p>受益人居住地</p>
              </td>
              <!-- <td style="width: 100px">
                <p>操作</p>
              </td> -->
            </tr>
            <tr v-for="(v, i) in beneInfosData" :key="i">
              <td style="width: 100px">
                <p>{{ v.beneName }}</p>
              </td>
              <td style="width: 100px">
                <p v-show="v.beneType == '01'">身份证</p>
                <p v-show="v.beneType == '04'">外国护照</p>
                <p v-show="v.beneType == '09'">中国香港居民-来往内地通行证</p>
                <p v-show="v.beneType == '10'">中国澳门居民-来往内地通行证</p>
                <p v-show="v.beneType == '11'">中国台湾居民-来往大陆通行证</p>
              </td>
              <td style="width: 100px">
                <p>{{ v.beneIdNum }}</p>
              </td>
              <td style="width: 100px">
                <p>{{ v.beneValidDateStart }}至{{  v.beneValidDateEnd == "forever" ? "长期" : v.beneValidDateEnd}} </p>
              </td>
              <td style="width: 100px">
                <p>
                  <a
                    href="javascript:;"
                    class="lianjie"
                    style="padding-left: 0"
                    @click="showImg(v.beneIdNomalPic)"
                    >{{ v.beneIdNomalPic ? "点击预览" : "" }}</a
                  >
                </p>
              </td>
              <td style="width: 100px">
                <p>
                  <a
                    href="javascript:;"
                    class="lianjie"
                    style="padding-left: 0"
                    @click="showImg(v.beneIdReversePic)"
                    >{{ v.beneIdReversePic ? "点击预览" : "" }}</a
                  >
                </p>
              </td>
              <td style="width: 100px">
                <p>{{ v.beneAddress }}</p>
              </td>
              <!-- <td style="width: 100px">
                <p>
                  <i class="lianjie" @click="editTab(v, i)">编辑</i>
                </p>
              </td> -->
            </tr>
          </table>
        </div>
        <h6>报备商户号产品详情</h6>
        <div class="tab1" style="padding: 0 20px; min-height: 100px">
          <table class="tab">
            <tr class="tab_title">
              <td>
                <p>小程序码</p>
              </td>
              <td>
                <p>实名认证驳回</p>
                <p>实名认证驳回原因</p>
              </td>
              <td>
                <p>支付产品</p>
                <p>开通状态</p>
              </td>
              <td>
                <p>错误代码</p>
                <p>错误描述</p>
              </td>
              <td>
                <p>商户简称</p>
                <p>客服电话</p>
              </td>
              <td>
                <p>第三方商户号</p>
                <p>渠道商户号</p>
              </td>
            </tr>
            <tr v-for="(v, i) in tabDetail" :key="i">
              <td>
                <p>
                  <a
                    href="javascript:;"
                    class="lianjie"
                    style="padding-left: 0"
                    @click="showImg(v.qrCodeUrl)"
                    >{{ v.qrCodeUrl ? "点击预览" : "" }}</a
                  >
                </p>
              </td>
              <td>
                <p>{{ v.rejectParam }}</p>
                <p>{{ v.rejectReason }}</p>
              </td>
              <td>
                <p>{{ productCode[v.productCode] }}</p>
                <p>{{ v.productStatus }}</p>
              </td>
              <td>
                <p>{{ v.productBizCode }}</p>
                <p style="width: 180px;overflow: hidden; white-space: break-spaces;margin:auto;">{{ v.productBizMsg }}</p>
              </td>
              <td>
                <p>{{ v.productShortName }}</p>
                <p>{{ v.productServicePhone }}</p>
              </td>
              <td>
                <p>{{ v.productChannelMchNo }}</p>
                <p>{{ v.productChannelMchId }}</p>
              </td>
            </tr>
          </table>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="detailShow = false"
            >取 消</el-button
          >
          <el-button class="addBt" @click="detailShow = false">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="备注" :visible.sync="remarkShow" width="490px">
        <ul class="overAccount_ul">
          <li style="width: 300px; margin: 0 auto">
            <!-- <div class="overAccount_ul_title">备注</div> -->
            <el-input
              type="textarea"
              v-model="remarkForm.remark"
              show-password
              placeholder="输入备注"
              :autosize="{ minRows: 6 }"
              style="padding: 20px 0"
            ></el-input>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="remarkShow = false"
            >取 消</el-button
          >
          <el-button class="addBt" @click="editRemark">确 定</el-button>
        </span>
      </el-dialog>
      <div class="cover_view" v-if="showImgUrl !== ''" @click="showImgUrl = ''">
        <a :href="showImgUrl" target="_blank">
          <img :src="showImgUrl" alt="加载失败" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getTrademchnos,
  getManualDetail,
  setTradeSync,
  getTradeAudit,
  setTradeActivate,
  getApi,
  delTrade,
  setReamrk,
} from "@/api/user/shopList.js";
import address from "@/utils/address.json";
import category from "@/utils/category.json";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      query: {
        pageNo: 1,
        pageSize: 10,
      },
      totalNum: 0,
      tabData: [],
      detail: {},
      config:{},
      InfoType: "",
      detailShow: false, //报备商户号信息详情展示
      tabDetail: [],
      showImgUrl: "",
      merchantType: {
        ENTERPRISE: "企业",
        INDIVIDUAL_BUSINESS: "个体工商户",
        MICRO_ENTERPRISE: "小微商户",
        PUBLIC_INSTITUTION: "事业单位",
        PRIVATE_NONENTERPRISE_UNIT: "民办非企业单位",
        SOCIAL_ORGANIZATION: "社会团体",
      },
      legalPersonCemType: {
        "01": "身份证",
        "04": "外国护照",
        "09": "中国香港居民-来往内地通行证",
        10: "中国澳门居民-来往内地通行证",
        11: "中国台湾居民-来往大陆通行证",
      },
      licenceType: [
        "统一社会信用代码证书",
        "有偿服务许可证（军队医院适用）",
        "医疗机构执业许可证（军队医院适用）",
        "企业营业执照（挂靠企业的党组织适用）",
        "组织机构代码证（政府机关适用）",
        "社会团体法人登记证书",
        "民办非企业单位登记证书",
        "基金会法人登记证书",
        "慈善组织公开募捐资格证书",
        "农民专业合作社法人营业执照",
        "宗教活动场所登记证",
        "营业执照",
        "营业执照号码（多合一）",
        "事业单位法人证书",
        "其他证书/批文/证明",
      ],
      microType: {
        MICRO_TYPE_STORE: "店铺场所",
        MICRO_TYPE_MOBILE: "流动经营/便民服务",
        MICRO_TYPE_ONLINE: "线上商品/服务交易",
      },
      productCode: {
        wxpay: "微信支付",
        alipay: "支付宝支付",
        unionpay: "银联支付",
      },
      address,
      category,
      category2: "",
      category3: "",
      accountType: {
        COMPANY: "企业",
        PERSONAL: "个人",
      },
      remarkForm: {},
      remarkShow: false,
      beneInfosData:[],
      OFFON_ActivateStatus:'ACCOUNT:STORE:STORES:TRADE/ENABLED',

      queryID:"",

    };
  },
  computed: {
    ...mapState({
      configDetail: "user_storesConfigDetail",
    }),
  },
  created() {
    if (this.configDetail) {
      this.query = this.configDetail.query;
    }
    this.$nextTick(() => {
      this.$refs.pagination.internalCurrentPage = this.query.pageNo;
    });
    this.InfoType = this.$route.query.InfoType;
    this.queryID = this.$route.query.id;
    this.getLists();
    this.listJurisdiction();
  },
  methods: {
    ...mapMutations({
      setConfigDetail: "user_setStoresConfig",
    }),
    //获取门户详情（已废弃）
    getDetail() {
      if (this.InfoType == "MANUAL") {
        getManualDetail(this.$route.query.id).then((res) => {
          if (res) {
            this.detail = res.resultData.storeDetail;
          }
        });
      } else {
        getApi(this.$route.query.id).then((res) => {
          if (res) {
            this.detail = res.resultData.storeDetail;
            this.getAddress();
            this.getCategory();
          }
        });
      }
    },
    //获取地址
    getAddress() {
      let province = this.address.find(
        (v) => v.provinceCode == this.detail.province
      ).citys;
      this.detail.province = this.address.find(
        (v) => v.provinceCode == this.detail.province
      ).province;
      let city = province.find((v) => v.cityCode == this.detail.city).countys;
      this.detail.city = province.find(
        (v) => v.cityCode == this.detail.city
      ).city;
      this.detail.county = city.find(
        (v) => v.countyCode == this.detail.county
      ).county;
    },
    //获取经营类目
    getCategory() {
      for (let i in this.category) {
        for (let ind in this.category[i][0]) {
          for (let index in this.category[i][0][ind]) {
            if (
              this.category[i][0][ind][index]["编号"] == this.detail.category
            ) {
              this.category2 = i;
              this.category3 = ind;
              this.detail.category =
                this.category[i][0][ind][index]["二级类目"];
            }
          }
        }
      }
    },
    //获取列表
    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
      }
      getTrademchnos(this.queryID, this.query).then((res) => {
        if (res.resultStatus) {
          this.tabData = res.resultData.storeTrades;
          this.totalNum = res.resultData.totalNum;
        }
      });
    },
    //同步
    setTradeSync(id) {
      setTradeSync(this.queryID, id).then((res) => {
        if (res.resultStatus) {
          this.getLists(false);
          this.$message.success("同步成功");
        }
      });
    },
    //详情页展示
    tabShow(value) {
      getTradeAudit(this.$route.query.id, value.tradeMchNo).then((res) => {
        if (res.resultStatus) {
          this.detail = res.resultData.detail;
          this.detail.licenceType = Number(this.detail.licenceType) - 1;
          this.getAddress();
          this.getCategory();
          this.tabDetail = res.resultData.products;
          this.beneInfosData =res.resultData.beneInfos;
          this.config = res.resultData.config;
          this.detailShow = true;
        }
      });
    },
    // 浏览单个图片
    showImg(val) {
      this.showImgUrl = val;
    },
    //设置状态
    setStatus(value) {
      let data = {
        activateStatus: value.activateStatus,
        tradeMchNo: value.tradeMchNo,
      };
      setTradeActivate(this.$route.query.id, data).then((res) => {
        if (!res) {
          value.activateStatus = value.activateStatus ? false : true;
        }
      });
    },
    // 修改
    editTrade(v) {
      this.$router.push({
        name: "shoplist_xq_shop_xg",
        query: {
          id: this.$route.query.id,
          tradeMchNo: v.tradeMchNo,
        },
      });
    },
    del(id) {
      this.$confirm("是否执行删除操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delTrade(this.$route.query.id, id).then((res) => {
            if (res.resultStatus) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getLists(false);

              // this.getDetail();
            }
          });
        })
        .catch(() => {
          this.$message.info("已取消删除");
        });
    },
    // 设置备注
    setRemark(value) {
      this.remarkShow = true;
      this.remarkForm = {
        tradeMchNo: value.tradeMchNo,
        remark: value.remark,
      };
    },
    editRemark() {
      const data = {
        storeId: this.$route.query.id,
        ...this.remarkForm,
      };
      setReamrk(data).then((res) => {
        this.remarkShow = false;
        this.getLists(false);
      });
    },
    back() {
      this.$router.push("/user/add_subaccountlist_js");
    },
    // 页面修改状态的跳转
    toTypeUrl(name, obj = null) {
      this.$router.push({
        name: name,
        query: {
          pageType: "edit",
          id: this.$route.query.id,
          InfoType: this.$route.query.InfoType,
          ...obj,
        },
      });
    },
    // 点击产品报备时
    toProductReport(value) {
      this.$router.push({
        path: "shoplist_xq_shop_cp",
        query: {
          pageType: "edit",
          id: this.$route.query.id,
          tradeMchNo: value.tradeMchNo,
          InfoType: this.$route.query.InfoType,
        },
      });
    },
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
    //判断列表按钮是否有权限
    listJurisdiction(){
        const _resources = JSON.parse(sessionStorage.getItem('userInfo')).resources;
        if(_resources=='ALL'){
            this.OFFON_ActivateStatus = 'ACCOUNT:STORE:STORES:TRADE/ENABLED';
        }else{
          if(_resources.split(';').indexOf('ACCOUNT:STORE:STORES:TRADE/ENABLED') !== -1 ){
            this.OFFON_ActivateStatus = 'ACCOUNT:STORE:STORES:TRADE/ENABLED';
          }else{
            this.OFFON_ActivateStatus = "";
          }
        }
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
    };
    this.setConfigDetail(data);
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";


.tableLineHead{
  display: flex;
  align-items: center;
  height: 60px;
}
.tableLineHead th , .tableLineTit th{
  display: flex;
  align-content: center;
  line-height: 60px;
}
.tableLineHead th p , .tableLineTit th p{
  padding: 0px 6px;
  min-width: 120px;
}
.tableLineTit th{
  border-bottom: 1px solid rgba(220, 224, 230, 1);
}
.tableLineTit th:last-child{
  border-bottom: none;
}
.next {
  width: 120px;
  height: 32px;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  padding: 0;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #dce0e6;
  border-radius: 3px;
  padding: 0;
  font-size: 12px;
  color: #333333;
}

.btn {
  width: 100%;
  text-align: center;
  height: 33px;
  margin-top: 50px;
  margin-bottom: 18px;
}


.lianjie {
  color: #008aff;
  padding: 0 5px 0 5px;
  cursor: pointer;
}

.tab tr td p {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}

.tab_title {
  background: rgba(244, 245, 246, 1);
  border-top: 1px solid rgba(220, 224, 230, 1);
}

.tab1 .tab tr {
  padding-left: 20px;
  height: 60px;
  border-bottom: 1px solid rgba(220, 224, 230, 1);
}

.tab {
  border-collapse: collapse;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid rgba(220, 224, 230, 1);
}

.tab1 {
  margin-top: 20px;
  min-height: 600px;
}


.title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 40px;
}



.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  width: 490px;
  float: left;
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}

.h36 {
  min-height: 36px;
  line-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.h36 a {
  color: #008aff !important;
  font-size: 14px;
  font-family: Microsoft YaHei;
}

.cover_view {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2020;
  background-color: rgba(0, 0, 0, 0.3);
}

.cover_view img {
  position: absolute;
  min-height: 200px;
  min-width: 200px;
  max-height: 100%;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}
h6 {
  font-size: 16px;
  line-height: 75px;
  color: #333333;
  margin-left: 20px;
  margin-bottom: -20px;
}
</style>
